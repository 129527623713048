import React from 'react';
import { BsTwitter, BsGithub } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <BsTwitter onClick={() => window.open('https://twitter.com/ReemNawaf')} />
    </div>
    <div>
      <BsGithub onClick={() => window.open('https://github.com/ReemNawaf')} />
    </div>
    
  </div>
);

export default SocialMedia;
